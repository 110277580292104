import React from 'react';
import $ from 'jquery';
import { PivotGrid } from 'devextreme-react';
import {
  FieldChooser,
  FieldPanel,
  StateStoring,
} from 'devextreme-react/pivot-grid';
import Store from 'devextreme/data/abstract_store';
import CustomStore from 'devextreme/data/custom_store';
import PivotGridDataSource, {
  PivotGridDataSourceField,
} from 'devextreme/ui/pivot_grid/data_source';

import { config, localization } from '../../settings';

import onExport, { ExportOptions } from './export';
import buildFilter from './filter';
import pivotGridPlug from './pivotGridPlug';
import buildSummary from './summary';
import { formatters } from '../../tools';
import { PivotReportTableProps } from './types';
import {
  generateFontSizeStyle,
  getSourceFieldFromReportField,
  isEqualPivotFields,
  loadData,
  onCellPrepared,
  onContentReady,
  reportTableContainerClass,
  sortFields,
} from './helpers';
import { ReportFieldUpdate } from '../../models/report/field';

class PivotReportTable extends React.Component<
  PivotReportTableProps,
  { precision: { natural: number; finance: number; calculate: number } }
> {
  constructor(props: PivotReportTableProps) {
    super(props);

    this.pivotGridRef = null;
    this.dataSource = this.createDataSource();
    this.state = {
      precision: {
        natural: 0,
        finance: 2,
        calculate: 2,
      },
    };
  }

  pivotGridRef: PivotGrid | null;
  dataSource: PivotGridDataSource;
  previousRequestData: any = null;
  previousResponseData: any = null;
  previousResponseSummary: any = null;

  componentDidMount() {
    this.syncDataSource();
  }

  componentDidUpdate() {
    this.syncDataSource();
  }

  componentWillUnmount() {
    this.dataSource.dispose();
  }

  // Expose Export method to the parent component
  export = () => {
    const jComponent = this.pivotGridRef?.instance;
    if (!jComponent) {
      return;
    }
    const options: ExportOptions = {
      component: jComponent,
    };
    onExport(options);
  };

  createDataSource = (): PivotGridDataSource => {
    const dataSourceStore: Store = new CustomStore({
      loadMode: 'processed',
      load: (loadOptions) => {
        const { nodeId, report, token, balances, treeId } = this.props;

        const headers = new Headers([
          ['content-type', 'application/json'],
          ['Authorization', `Bearer ${token}`],
        ]);
        if (!loadOptions.group) {
          return loadData(
            nodeId,
            report?.date[0] || null,
            report?.date[1] || null,
            token,
          );
        }

        const url = `${config.api_url}/reports/${nodeId}/pivot`;
        let balTypes = loadOptions.groupSummary.map((summaryItem: any) => {
          return (
            balances[treeId]?.list?.find(
              (bal) => bal.id === Number(summaryItem.selector.split('.')[1]),
            )?.typeId ?? 1
          );
        });

        const result = $.Deferred();

        const data = {
          filter: buildFilter(loadOptions.filter, pivotGridPlug.revert) || [],
          group: loadOptions.group,
          groupSummary: buildSummary(loadOptions.groupSummary),
          totalSummary: buildSummary(loadOptions.totalSummary),
          nodeId,
          startDate: report?.date[0],
          endDate: report?.date[1],
          dateType: report?.dateType,
        };

        if (JSON.stringify(data) === JSON.stringify(this.previousRequestData)) {
          const data = this.previousResponseData;
          const summary = this.previousResponseSummary;
          result.resolve(data, summary != null ? { summary: summary } : null);
          return result;
        }

        this.previousRequestData = data;
        fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            let data = [];
            let summary = null;
            if ('data' in response) {
              data = response.data.map((e: any) =>
                pivotGridPlug.convert(e, balTypes, this.state.precision),
              );
              this.previousResponseData = data;
            }
            if ('totalSummaries' in response) {
              summary = response.totalSummaries.map(
                (sum: number, i: number) => {
                  if (balTypes[i] === 1) {
                    return formatters.formatNumber(
                      sum,
                      this.state.precision.natural,
                    );
                  } else if (balTypes[i] === 2) {
                    return formatters.formatNumber(
                      sum,
                      this.state.precision.finance,
                    );
                  } else {
                    return formatters.formatNumber(
                      sum,
                      this.state.precision.calculate,
                    );
                  }
                },
              );
              this.previousResponseSummary = summary;
            }
            result.resolve(
              data,
              summary != null
                ? {
                    summary: summary,
                  }
                : null,
            );
          });

        return result;
      },
    });

    return new PivotGridDataSource({
      remoteOperations: true,
      retrieveFields: false,
      store: dataSourceStore,
      fields: [],
      onChanged: () => {
        const { nodeId, report, updateReportField } = this.props;
        const propsFields = (report?.fields || []).map((f) =>
          getSourceFieldFromReportField(f),
        );
        const pivotFields = this.dataSource.fields();

        const updates = pivotFields.filter((pivotField) => {
          const propsField = propsFields.find(
            (pf) => pf.dataField === pivotField.dataField,
          );
          return !isEqualPivotFields(pivotField, propsField);
        });

        if (updates.length > 0) {
          updates.forEach((u) => {
            if (u.dataField) {
              const update: ReportFieldUpdate = {
                area: u.area,
                areaIndex: u.areaIndex,
                filterType: u.filterType,
                filterValues: u.filterValues,
              };
              updateReportField(nodeId, u.dataField, update);
            }
          });
        }
      },
    });
  };

  syncDataSource = () => {
    const { report } = this.props;

    const pivotFields = this.dataSource.fields();
    const propsFields = (report?.fields || []).map((f) =>
      getSourceFieldFromReportField(f),
    );
    const updates = propsFields.filter((currentField) => {
      const oldField = pivotFields.find(
        (pf) => pf.dataField === currentField.dataField,
      );
      return !isEqualPivotFields(oldField, currentField);
    });
    if (updates.length > 0) {
      const allIds = [
        ...pivotFields.map((f) => f.dataField),
        ...propsFields.map((f) => f.dataField),
      ].filter((id, index, arr) => arr.indexOf(id) === index);

      const updatedFields = allIds
        .map((id) => {
          const foundUpdate = updates.find((u) => u.dataField === id);
          if (foundUpdate) {
            return foundUpdate;
          }
          return pivotFields.find((f) => f.dataField === id);
        })
        .filter((f): f is PivotGridDataSourceField => !!f);

      this.dataSource.fields(updatedFields);
      sortFields(this.dataSource);
      // this.dataSource.load();

      onContentReady();
    }
  };

  render() {
    const { className, nodeId, zoom } = this.props;

    const effectiveClassName = className
      ? `${reportTableContainerClass} ${className}`
      : reportTableContainerClass;

    return (
      <div className={effectiveClassName}>
        <PivotGrid
          ref={(ref) => (this.pivotGridRef = ref)}
          className={`${reportTableContainerClass}__table`}
          style={generateFontSizeStyle(zoom)}
          dataSource={sortFields(this.dataSource)}
          onCellPrepared={onCellPrepared}
          onExporting={onExport}
          allowExpandAll
          showBorders
          height={'100%'}
          texts={{
            noData: localization.reports_page_table_no_data,
            total: localization.reports_page_table_total,
            grandTotal: localization.reports_page_table_grand_total,
          }}
          onContentReady={onContentReady}
          stateStoring={{
            enabled: false,
          }}
        >
          <StateStoring
            enabled={true}
            storageKey={`pivot-report-store-key-${nodeId}`}
            savingTimeout={0}
          />
          <FieldPanel
            visible
            texts={{
              columnFieldArea: localization.reports_page_table_columns,
              rowFieldArea: localization.reports_page_table_rows,
              dataFieldArea: localization.reports_page_table_data,
              filterFieldArea: localization.reports_page_table_filters,
            }}
          />
          <FieldChooser enabled={false} />
        </PivotGrid>
      </div>
    );
  }
}

export default PivotReportTable;
