import { Moment } from 'moment';
import type PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import type { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';
import type dxPivotGrid from 'devextreme/ui/pivot_grid';
import type { dxPivotGridPivotGridCell } from 'devextreme/ui/pivot_grid';
import type { dxElement } from 'devextreme/core/element';
import pivotGridPlug from './pivotGridPlug';
import { config, localization } from '../../settings';
import type { report } from '../../models';

export const reportTableContainerClass = 'pivot-report-table';

export const generateFontSizeStyle = (
  zoom: number,
): React.CSSProperties | undefined => {
  if (zoom >= 2) {
    return {
      fontSize: '24px',
    };
  } else if (zoom >= 1.5) {
    return {
      fontSize: '20px',
    };
  } else if (zoom >= 1.25) {
    return {
      fontSize: '16px',
    };
  } else if (zoom >= 1) {
    return {
      fontSize: '14px',
    };
  } else if (zoom >= 0.9) {
    return {
      fontSize: '12px',
    };
  } else if (zoom >= 0.75) {
    return {
      fontSize: '10px',
    };
  } else if (zoom >= 0.5) {
    return {
      fontSize: '6px',
    };
  }
};

export const isEqualPivotFields = (
  a?: PivotGridDataSourceField,
  b?: PivotGridDataSourceField,
): boolean => {
  if (!a || !b) {
    return !!a === !!b;
  }
  type PropertiesType = 'area' | 'areaIndex' | 'filterType' | 'filterValues';
  const properties: Array<PropertiesType> = [
    'area',
    'areaIndex',
    'filterType',
    'filterValues',
  ];
  for (let i = 0; i < properties.length; i++) {
    const propertyName = properties[i];
    if (a[propertyName] !== b[propertyName]) {
      return false;
    }
  }
  return true;
};

export const sortFields = (
  dataSource: PivotGridDataSource,
): PivotGridDataSource => {
  const fields = dataSource.fields();
  const sortedFields = fields.map<PivotGridDataSourceField>((field) => {
    if (
      field.dataField?.includes('month') ||
      field.dataField?.includes('year') ||
      field.dataField?.includes('day')
    ) {
      return {
        ...field,
        sortingMethod(a: any, b: any) {
          const monthA = Number(a.value?.toString().split('-')[0]);
          const monthB = Number(b.value?.toString().split('-')[0]);

          if (monthA === monthB) {
            return 0;
          }
          return monthA > monthB ? 1 : -1;
        },
      };
    }
    return field;
  });
  dataSource.fields(sortedFields);
  // this.dataSource.load();

  return dataSource;
};

export const getSourceFieldFromReportField = (origin: report.Field) => {
  const isMeasure =
    origin.type === 'balance' || origin.type === 'calculatedBalance';
  let caption = origin.name;
  if ('relates' in origin) {
    switch (origin.relates) {
      case 'node':
        caption = `${caption} ${localization.reports_page_node_postfix}`;
        break;
      case 'correspondent':
        caption = `${caption} ${localization.reports_page_correspondent_postfix}`;
        break;
      default: {
        caption = `${caption} (o_o)`;
      }
    }
  }
  const result: PivotGridDataSourceField = {
    dataField: origin.id,
    caption: caption,
    isMeasure: isMeasure,
    summaryType: isMeasure ? 'sum' : undefined,
    // @ts-ignore
    balType: origin.balType ?? undefined,
    customizeText: isMeasure ? undefined : pivotGridPlug.getText,
    sortingMethod: pivotGridPlug.attributesSort,
    area: origin.area,
    areaIndex: origin.areaIndex,
    allowFiltering: true,
    filterType: origin.filterType,
    filterValues: origin.filterValues,
  };
  return result;
};

export const onCellPrepared = (e: {
  component?: dxPivotGrid | undefined;
  element?: dxElement | undefined;
  model?: any;
  area?: string | undefined;
  cellElement?: dxElement | undefined;
  cell?: dxPivotGridPivotGridCell | undefined;
  rowIndex?: number | undefined;
  columnIndex?: number | undefined;
}) => {
  const { cell, area, cellElement } = e;
  if (!cell || !cellElement || !area) {
    return;
  }
  if (area === 'data') {
    if ((cell.text || '').length > 0) {
      const visibleText = cell.text;
      cellElement.innerHTML = `<span class='${reportTableContainerClass}__data'>${visibleText}</span>`;
    }
    if (
      cell.columnType === 'D' &&
      cell.rowType === 'D' &&
      (cell.text || '').length < 1
    ) {
      cellElement.innerHTML = `<span class='${reportTableContainerClass}__data'>0</span>`;
    }
  }
};

export const onContentReady = () => {
  const headerFiltersDivList = document.querySelectorAll(
    '.dx-pivotgrid-fields-area.dx-area-fields',
  );

  const secondActiveHeaderFilterDiv = headerFiltersDivList[1] as HTMLElement;
  const activeHeaderFilterDiv = headerFiltersDivList[3] as HTMLElement;
  const thirdActiveHeaderFilterDiv = headerFiltersDivList[2] as HTMLElement;

  const contentFilterDivList = document.querySelectorAll(
    '.dx-pivotgrid-vertical-headers.dx-pivotgrid-area',
  );

  const activeContentFilterDiv = contentFilterDivList[0] as HTMLElement;

  const contentDivList = document.querySelectorAll(
    '.dx-pivotgrid-horizontal-headers.dx-pivotgrid-area',
  );

  const activeContentDiv = contentDivList[0] as HTMLElement;

  const contentTableDivList = document.querySelectorAll(
    '.dx-pivotgrid-area.dx-pivotgrid-area-data',
  );

  const maxWidth = '600px';

  if (activeHeaderFilterDiv) {
    activeHeaderFilterDiv.style.maxWidth = maxWidth;
    activeHeaderFilterDiv.style.overflowX = 'auto';
    activeHeaderFilterDiv.classList.add('hide-scroll');

    activeHeaderFilterDiv.addEventListener('scroll', () => {
      const scrollContainer = activeContentFilterDiv.querySelector(
        '.dx-scrollable-container',
      ) as HTMLElement;
      scrollContainer.scrollLeft = activeHeaderFilterDiv.scrollLeft;
    });
  }

  if (secondActiveHeaderFilterDiv) {
    secondActiveHeaderFilterDiv.style.maxWidth = maxWidth;
    secondActiveHeaderFilterDiv.style.overflowX = 'auto';
    secondActiveHeaderFilterDiv.style.paddingRight = '8px';
  }

  if (thirdActiveHeaderFilterDiv) {
    thirdActiveHeaderFilterDiv.style.width = '100%';
    thirdActiveHeaderFilterDiv.style.overflowX = 'auto';
    thirdActiveHeaderFilterDiv.style.maxWidth = '100%';
  }

  if (activeContentFilterDiv) {
    activeContentFilterDiv.style.maxWidth = maxWidth;

    const scrollContainer = activeContentFilterDiv.querySelector(
      '.dx-scrollable-container',
    ) as HTMLElement;

    if (scrollContainer) {
      scrollContainer.style.overflowX = 'auto';
      scrollContainer.addEventListener('scroll', () => {
        const headerFilterDiv = activeHeaderFilterDiv as HTMLElement;
        headerFilterDiv.scrollLeft = scrollContainer.scrollLeft;
      });
    }
  }

  if (activeContentDiv) {
    activeContentDiv.style.width = '100%';

    const activeContentDivTable = activeContentDiv.querySelector(
      'table',
    ) as HTMLElement;

    if (activeContentDivTable) {
      activeContentDivTable.style.width = '100%';
    }
  }

  const secondContentTableList = document.querySelectorAll(
    '.dx-pivotgrid-area.dx-pivotgrid-area-data',
  );

  const secondContentTableDiv = secondContentTableList[0] as HTMLElement;

  const contentTableDiv = contentTableDivList[0] as HTMLElement;
  if (contentTableDiv) {
    contentTableDiv.style.width = '100%';
    contentTableDiv.style.maxWidth = '100%';

    const table = contentTableDiv.querySelector('table') as HTMLElement;

    if (table) {
      table.style.width = '100%';
      table.style.maxWidth = '100%';
    }
  }

  if (secondContentTableDiv) {
    secondContentTableDiv.style.width = '100%';
    secondContentTableDiv.style.maxWidth = '100%';
  }
};

export const loadData = (
  nodeId: number,
  startDate: Moment | null,
  endDate: Moment | null,
  token: string,
) => {
  if (!startDate || !endDate) {
    return [];
  }
  const startDateComponent = encodeURIComponent(startDate.format('YYYY-MM-DD'));
  const endDateComponent = encodeURIComponent(endDate.format('YYYY-MM-DD'));
  const url = `${config.api_url}/reports/${nodeId}/data?startDate=${startDateComponent}&endDate=${endDateComponent}&take=20`;
  const headers = new Headers([
    ['content-type', 'application/json'],
    ['Authorization', `Bearer ${token}`],
  ]);
  return fetch(url, {
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      let result = response;
      if ('data' in response) {
        result = response['data'];
      }
      return result;
    });
};
